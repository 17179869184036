<template>
  <section class="hero is-light is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="card-content">
          <figure class="image is-192x192 has-image-centered">
            <img class="is-rounded" src="./assets/sean.png">
          </figure>
        </div>
        <h1 class="title">
          👋 Hi, I'm Sean Bachelder
        </h1>
        <h2 class="subtitle">
          I'm a software engineer and I like to build things.
        </h2>
        <div class="block">
          <a href="https://github.com/sbacheld" target="_blank">
            <span class="icon is-large"><ion-icon name="logo-github"></ion-icon></span>
          </a>
          <a href="mailto:sean@bachelder.dev" target="_blank">
            <span class="icon is-large"><ion-icon name="mail"></ion-icon></span>
          </a>
          <a href="https://www.linkedin.com/in/seanbachelder/" target="_blank">
            <span class="icon is-large"><ion-icon name="logo-linkedin"></ion-icon></span>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>

<style>

</style>
